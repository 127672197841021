export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const SET_USER_DATA = "SET_USER_DATA";

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ERROR_MESSAGE = "Something went wrong.";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const LOGOUT = "LOGOUT";
