import React, { useState } from "react";
import { CHAMBER_FILTER_DATA } from "../../constants/constants";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import InputText from "../../common/form/InputText";
import { useDispatch } from "react-redux";
import { filterChamber } from "../../redux/actions/chamber_actions";

function ChamberFilter() {
  const [formData, setFormData] = useState(CHAMBER_FILTER_DATA);
  const [filterLoading, setFilterLoading] = useState(false);

  const dispatch = useDispatch();

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleFilter = () => {
    setFilterLoading(true);

    localStorage.setItem("chamber", formData.name);

    dispatch(filterChamber(`?name=${formData.name}`)).finally(() =>
      setFilterLoading(false)
    );
  };

  return (
    <div className="pt-5 sticky top-0 xl:block h-fit">
      <Card>
        <CardBody>
          <div className="flex items-center justify-between">
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Filter Search
            </Typography>

            <Button onClick={handleRefresh} variant="text" color="pink">
              Clear All
            </Button>
          </div>

          <div className="pt-5 flex flex-col gap-8">
            <InputText
              defaultValue={formData.name}
              updateType="name"
              labelTitle="Name"
              updateFormValue={updateFormValue}
            />
          </div>
        </CardBody>
        <CardFooter>
          <Button
            color="pink"
            onClick={() => handleFilter()}
            fullWidth
            loading={filterLoading}
          >
            Search
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ChamberFilter;
