import { Typography } from "@material-tailwind/react";
import React from "react";
import { IoLocation } from "react-icons/io5";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function ChamberCard({ data }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/chambers/${data.id}`)}
      className="border h-fit w-full border-solid bg-white border-gray-200  p-4 hover:border-purple-200  rounded-lg
                   lg:hover:scale-105 ease-in-out duration-75 transition-all  cursor-pointer hover:shadow-md transparent shadow-lg text-gray-700"
    >
      <div className="h-500">
        <Typography
          variant="h5"
          color="black"
          className="overflow-hidden line-clamp-2 h-[78px]"
        >
          {data.name}
        </Typography>
      </div>

      <div className="text-sm text-gray-00 flex flex-col gap-2 mt-3 ">
        <div className="flex items-center gap-2">
          <div>
            <BsFillPersonFill className="text-[#F7443D]" />
          </div>
          <p className="truncate">
            {data?.headOf?.title +
              " " +
              data?.headOf?.first_name +
              " " +
              data?.headOf?.last_name ?? "N/A"}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <IoLocation className="text-[#F7443D]" />
          </div>
          <p className="overflow-hidden line-clamp-2">
            {data?.address ?? "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChamberCard;
