import * as types from "../constants/chamber_constants";

const initialstate = {
  data: null,
  filter: null,
  single: null,
  chamberError: null,
};

const chamberReducer = (state = initialstate, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ALL_CHAMBERS_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.SINGLE_CHAMBER_SUCCESS:
      return { ...state, single: payload ? payload.chamber : null };

    case types.FILTER_CHAMBER_SUCCESS:
      return { ...state, filter: payload ? payload : null };

    case types.ALL_CHAMBERS_FAILURE:
    case types.FILTER_CHAMBER_FAILURE:
      return { ...state, chamberError: payload ? payload : null };

    default:
      return state;
  }
};

export default chamberReducer;
