import React from "react";
import heroImage from "../../assets/herodesk.png";
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleNavigate = () => navigate("/lawyers");

  return (
    <div className="grid lg:grid-cols-2 gap-8 w-full pt-[70px] h-screen overflow-hidden sm:overflow-scroll md:h-full  lg:pt-2  lg:fixed bg-[#EFEFF1]">
      <div className="p-3 md:pl-10 lg:pl-15 xl:pl-20 flex gap-8 flex-col justify-center">
        <Typography className="w-[3] text-[2.5rem] lg:text-[4rem] font-bold leading-tight lg:leading-[78px] text-[#374A5B]">
          Your Trusted Partner To Find A Legal Ally
        </Typography>
        <Typography>
          The Ghana Bar Association Lawyer Locator is the easiest way to find a
          lawyer in Ghana.
        </Typography>

        <div>
          <Button color="pink" size="lg" onClick={() => handleNavigate()}>
            Search Lawyer
          </Button>
        </div>

        {/* <div className=" bg-white p-1 rounded-lg lg:p-3 gap-3">
          <div className="relative flex w-full">
            <Input type="text" label="Name" />
            <Button
              size="sm"
              color="blue-gray"
              className="!absolute right-1 top-1 rounded"
            >
              Search
            </Button>
          </div>
          <Link to="/lawyers">
            <Typography color="pink" variant="small" className="pt-2">
              Click here to browser all lawyers
            </Typography>
          </Link>
        </div> */}
      </div>
      <div className=" h-[93vh] w-full ">
        <img
          src={heroImage}
          className="w-full h-full object-cover"
          alt="background illustration of hero section"
        />
      </div>
    </div>
  );
}

export default Home;
