export const LAWYER_FILTER_DATA = {
  name: "",
  region: "",
  practice_area: "",
  gender: "",
  start_date: "",
  end_date: "",
};

export const LAWYER_MESSAGE_DATA = {
  first_name: "",
  last_name: "",
  email: "",
  message: "",
  year: "",
};

export const CHAMBER_FILTER_DATA = {
  name: "",
};
