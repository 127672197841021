import { Input } from "@material-tailwind/react";
import { useState } from "react";

function InputText({
    labelTitle,
    type,
    icon,
    containerStyle,
    defaultValue,
    placeholder,
    updateFormValue,
    updateType,
    required = false,
    maxLength,
}) {
    const [value, setValue] = useState(defaultValue);

    const updateInputValue = (val) => {
        setValue(val);
        updateFormValue({ updateType, value: val });
    };

    return (
        <div className={`form-control w-full ${containerStyle}`}>
            <Input
                type={type || "text"}
                label={labelTitle}
                variant="outlined"
                size="lg"
                icon={icon}
                value={value}
                placeholder={placeholder || ""}
                onChange={(e) => updateInputValue(e.target.value)}
                required={required}
                color="black"
                maxLength={maxLength ? maxLength : null}
            />
        </div>
    );
}

export default InputText;
