import {
  ALL_CHAMBERS,
  FILTER_CHAMBERS,
  SINGLE_CHAMBER,
} from "../constants/api_contants";
import { handleApi } from "./utils";

export const allChambersApi = async (value) => {
  return handleApi("get", `${ALL_CHAMBERS}?page=${value}`);
};

export const singleChamberApi = async (value) => {
  return handleApi("get", `${SINGLE_CHAMBER}/${value}`);
};

export const filterChamberApi = async (value) => {
  return handleApi("get", FILTER_CHAMBERS + value);
};
