import { Button, Collapse, Navbar, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/auth_action";
import Cookies from "js-cookie";

import logo from "../../assets/logo.jpg";

function TopNavbar() {
    const [openNav, setOpenNav] = useState(false);

    const user = JSON.parse(localStorage.getItem("profile"))?.user;

    console.log("================================================================");
    console.log(user);

    const navList = (
        <ul className="mt-2 mb-4 pt-6 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Link to="/">
                <Typography as="li" variant="h5" color="blue-gray" className="p-1 font-normal">
                    <span className="flex items-center">Home</span>
                </Typography>
            </Link>
            <Link to="/lawyers">
                <Typography as="li" variant="h5" color="blue-gray" className="p-1 font-normal">
                    <span className="flex items-center">Browse Lawyers</span>
                </Typography>
            </Link>
            <Link to="/chambers">
                <Typography as="li" variant="h5" color="blue-gray" className="p-1 font-normal">
                    <span className="flex items-center">Browse Chambers</span>
                </Typography>
            </Link>

            {!user && (
                <Link to="/login">
                    <Typography
                        as="li"
                        variant="h5"
                        color="blue-gray"
                        className="p-1  border border-red-500 px-5 hover:bg-[#b6194d]  drop-shadow-md bg-[#E91E63]  text-white rounded-md font-normal"
                    >
                        <span className="flex items-center">Login</span>
                    </Typography>
                </Link>
            )}

            {user && (
                <Button
                    className="bg-[#E91E63]"
                    onClick={() => {
                        logout();
                        localStorage.clear();
                        Cookies.remove("dataCookie"); // Call the logout function
                        window.location.reload(); // Refresh the page
                    }}
                >
                    Logout
                </Button>
            )}
        </ul>
    );

    useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false));
    }, []);

    return (
        <div className="max-h-[768px] overflow-scroll">
            <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
                <div className="flex items-center justify-between text-blue-gray-900">
                    <div className="pt-4">
                        <a href="http://ghanabar.org/" target="_blank" rel="noopener noreferrer">
                            <img src={logo} className="h-14 mr-3" alt="Gba Logo" />
                        </a>
                    </div>

                    <div className="flex items-center gap-4">
                        <div className="mr-4 hidden lg:block">{navList}</div>
                    </div>
                </div>
                <Collapse open={openNav}>{navList}</Collapse>
            </Navbar>
        </div>
    );
}

export default TopNavbar;
