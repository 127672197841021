import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allLawyers, filterLawyer } from "../../redux/actions/lawyer_actions";
import AlertMessage from "../../common/action/AlertMessage";
import Loader from "../../common/loader/Loader";
import LawyerCard from "./LawyerCard";
import LawyerFilter from "./LawyerFilter";
import PreviousButton from "../../common/buttons/PreviousButton";
import NextButton from "../../common/buttons/NextButton";
import NoContent from "../../common/shared/NoContent";

function LawyerList() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [filterPage, setFilterPage] = useState(1);

  const dispatch = useDispatch();

  const { data, filter, lawyerError } = useSelector((state) => state.lawyer);

  const link = localStorage.getItem("lawyer");

  const fetchLawyers = (pageNumber) => {
    setLoading(true);
    setPage(pageNumber);
    dispatch(allLawyers(pageNumber)).finally(() => setLoading(false));
  };

  const fetchFilter = (page) => {
    setLoading(true);
    setFilterPage(page);
    dispatch(filterLawyer(`${link}&page=${page}`)).finally(() =>
      setLoading(false)
    );
  };

  const handleNextPage = () => {
    if (filter) {
      if (filterPage < filter.totalPages) {
        fetchFilter(filterPage + 1);
      }
    } else {
      if (page < data.totalPages) {
        fetchLawyers(page + 1);
      }
    }
  };

  const handlePreviousPage = () => {
    if (filter) {
      if (filterPage > 1) {
        fetchFilter(filterPage - 1);
      }
    } else {
      if (page > 1) {
        fetchLawyers(page - 1);
      }
    }
  };

  useEffect(() => {
    fetchLawyers(page);
  }, [page]);

  useEffect(() => {
    if (lawyerError) {
      setErrorMessage(lawyerError);
    }
  }, [lawyerError]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="flex items-center shadow-xl px-3 md:px-10 lg:15 xl:px-20 banner">
        <div className="flex w-full flex-col md:flex-row justify-between gap-2 items-center">
          <h2 className="text-[2rem] md:text-[3rem] text-white font-bold">
            {filter && filter?.users?.length === 0
              ? 0
              : filter?.count || data?.count}{" "}
            Lawyers
          </h2>
        </div>
      </div>

      {errorMessage && <AlertMessage type="error" message={errorMessage} />}

      <div className="grid grid-cols-1 static top-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-8 px-3 md:px-10 lg:15 xl:px-20">
        <div className="py-5 lg:col-span-2 xl:col-span-3 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-3 lg:gap-8">
          {(filter && filter.users.length === 0) ||
          (data && data.users.length === 0) ? (
            <NoContent message="No data to display" />
          ) : (
            (filter ? filter.users : data.users).map((item, index) => (
              <LawyerCard key={index} data={item} />
            ))
          )}
        </div>

        <div className="none lg:block ">
          <LawyerFilter />
        </div>
      </div>

      <div className="py-12 flex justify-center gap-4">
        <PreviousButton
          onClick={handlePreviousPage}
          page={filter ? filterPage : page}
        />
        <NextButton
          onClick={handleNextPage}
          page={filter ? filterPage : page}
          total={filter ? filter.totalPages : data.totalPages}
        />
      </div>
    </>
  );
}

export default LawyerList;
