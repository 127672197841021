export const ALL_LAWYERS_SUCCESS = "ALL_LAWYERS_SUCCESS";
export const ALL_LAWYERS_FAILURE = "ALL_LAWYERS_FAILURE";

export const SINGLE_LAWYER_SUCCESS = "SINGLE_LAWYER_SUCCESS";
export const SINGLE_LAWYER_FAILURE = "SINGLE_LAWYER_FAILURE";

export const YEAR_LAWYER_SUCCESS = "YEAR_LAWYER_SUCCESS";
export const YEAR_LAWYER_FAILURE = "YEAR_LAWYER_FAILURE";

export const REGION_LAWYER_SUCCESS = "REGION_LAWYER_SUCCESS";
export const REGION_LAWYER_FAILURE = "REGION_LAWYER_FAILURE";

export const FILTER_LAWYER_SUCCESS = "FILTER_LAWYER_SUCCESS";
export const FILTER_LAWYER_FAILURE = "FILTER_LAWYER_FAILURE";

export const CONTACT_LAWYER_SUCCESS = "CONTACT_LAWYER_SUCCESS";
export const CONTACT_LAWYER_FAILURE = "CONTACT_LAWYER_FAILURE";
