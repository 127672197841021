const createAsyncAction =
  (apiFxn, actionTypes) => (payload) => async (dispatch) => {
    try {
      const response = await apiFxn(payload);
      const { error, data } = response;

      if (error) {
        dispatch({ type: actionTypes.failure, payload: error });
      } else {
        dispatch({ type: actionTypes.success, payload: data });
      }
    } catch (error) {
      dispatch({ type: actionTypes.failure, payload: error.message });
    }
  };

export default createAsyncAction;
