import * as api from "../api/auth_api";
import * as types from "../constants/auth_constants";
import { isValidToken } from "../../utils/authUtils";
import createAsyncAction from "../../utils/asyncAction";

export const setAccessToken = (accessToken) => async (dispatch) => {
    dispatch({ type: types.SET_ACCESS_TOKEN, payload: accessToken });
};

export const setUserData = (userData) => async (dispatch) => {
    dispatch({ type: types.SET_USER_DATA, payload: userData });
};

export const initializeAuth = () => async (dispatch) => {
    const accessToken = JSON.parse(localStorage.getItem("profile"))?.token;

    if (accessToken) {
        if (isValidToken(accessToken)) {
            dispatch(setAccessToken(accessToken));
            dispatch(setUserData(JSON.parse(localStorage.getItem("profile")).user));
        }
    }
};

export const signInAction = (formData, navigate) => async (dispatch) => {
    try {
        const response = await api.signIn(formData);
        const { error, data } = response;

        if (error) {
            dispatch({ type: types.SIGNIN_FAIL, payload: error });
        } else {
            const { user, token } = data;
            const profile = { user, token };
            localStorage.setItem("profile", JSON.stringify(profile));

            dispatch({ type: types.SIGNIN_SUCCESS, payload: profile });
            navigate("/lawyers");
        }
    } catch (error) {
        dispatch({ type: types.SIGNIN_FAIL, payload: types.ERROR_MESSAGE });
    }
};

export const logout = () => async (dispatch) => {
    try {
        localStorage.clear();
        dispatch({ type: types.LOGOUT, payload: null });
    } catch (error) {
        console.log(error);
    }
};

const getProfileTypes = {
    success: types.GET_PROFILE_SUCCESS,
    failure: types.GET_PROFILE_FAILURE,
};

export const getProfile = createAsyncAction(api.getProfile, getProfileTypes);
