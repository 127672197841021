import { combineReducers } from "@reduxjs/toolkit";

import lawyerReducer from "./lawyer_reducers";
import chamberReducer from "./chamber_reducers";
import authReducer from "./auth_reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  lawyer: lawyerReducer,
  chamber: chamberReducer,
});

export default rootReducer;
