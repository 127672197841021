import createAsyncAction from "../../utils/asyncAction";

import * as api from "../api/chamber_api";
import * as types from "../constants/chamber_constants";

const getAllChambersTypes = {
    success: types.ALL_CHAMBERS_SUCCESS,
    failure: types.ALL_CHAMBERS_FAILURE,
};

const getSingleChamberTypes = {
    success: types.SINGLE_CHAMBER_SUCCESS,
    failure: types.SINGLE_CHAMBER_FAILURE,
};

const getChamberFilterTypes = {
    success: types.FILTER_CHAMBER_SUCCESS,
    failure: types.FILTER_CHAMBER_FAILURE,
};

export const allChambers = createAsyncAction(api.allChambersApi, getAllChambersTypes);

export const singleChamber = createAsyncAction(api.singleChamberApi, getSingleChamberTypes);

export const filterChamber = createAsyncAction(api.filterChamberApi, getChamberFilterTypes);
