import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, Spinner, Typography } from "@material-tailwind/react";
import { signInAction } from "../../redux/actions/auth_action";
import AlertMessage from "../../common/action/AlertMessage";
import InputText from "../../common/form/InputText";
import InputPassword from "../../common/form/InputPassword";

import logo from "../../assets/logo.jpg";

const LOGIN_DATA = {
    email: "",
    password: "",
};

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState(LOGIN_DATA);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signInError = useSelector((state) => state.auth?.signInError);

    const updateFormValue = ({ updateType, value }) => {
        setLoginData({ ...loginData, [updateType]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(signInAction(loginData, navigate)).finally(() => setLoading(false));
    };

    return (
        <div className="min-h-screen flex items-center">
            <Card className="mx-auto w-full max-w-xl shadow-xl">
                <div className="py-24 px-10">
                    <div className="text-center">
                        <img src={logo} alt="gba logo" className="inline-block" />
                        <Typography color="black" variant="h4" className="mb-2 mt-8">
                            Login To Account
                        </Typography>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-16 mt-12">
                            {signInError && <AlertMessage type="error" message={signInError} />}

                            <InputText
                                type="text"
                                defaultValue={loginData.username}
                                updateType="username"
                                containerStyle="mt-4"
                                labelTitle="Username"
                                updateFormValue={updateFormValue}
                                required={true}
                            />

                            <InputPassword
                                defaultValue={loginData.password}
                                updateType="password"
                                containerStyle="mt-4"
                                labelTitle="Password"
                                updateFormValue={updateFormValue}
                                required={true}
                            />

                            <div className="text-right mt-2">
                                <a
                                    href="https://svbredte545fx.ghanabar.org/forgot"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Typography variant="small" color="pink">
                                        Forgot Password
                                    </Typography>
                                </a>
                            </div>
                        </div>

                        <Button
                            variant="filled"
                            className="text-center flex items-center justify-center"
                            size="lg"
                            fullWidth={true}
                            type="submit"
                        >
                            {loading ? <Spinner color="white" /> : "Login"}
                        </Button>

                        <div className="mt-4 text-center">
                            <a
                                href="https://svbredte545fx.ghanabar.org/signup"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Typography variant="small" color="pink">
                                    Dont have an account? Register Here
                                </Typography>
                            </a>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    );
};

export default LoginPage;
