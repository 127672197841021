export const getYearsFrom1960 = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1960;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ id: year, name: year });
  }

  years.sort((a, b) => b.id - a.id);

  return years;
};
