import { handleApi } from "./utils";
import { GET_PROFILE, LOGIN_API } from "./../constants/api_contants";

export const signIn = async (values) => {
    return handleApi("post", LOGIN_API, values);
};

export const getProfile = async () => {
    return handleApi("get", GET_PROFILE);
};
