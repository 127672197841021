import {
  ALL_LAWYERS,
  CONTACT_LAWYER,
  FILTER_LAWYERS,
  REGION_LAWYER,
  SINGLE_LAWYER,
  YEAR_LAWYER,
} from "../constants/api_contants";
import { handleApi } from "./utils";

export const allLawyersApi = async (value) => {
  return handleApi("get", `${ALL_LAWYERS}?page=${value}`);
};

export const singleLawyerApi = async (value) => {
  return handleApi("get", `${SINGLE_LAWYER}/${value}`);
};

export const yearLawyerApi = async (value) => {
  return handleApi("get", `${YEAR_LAWYER}/${value.id}?page=${value.page}`);
};

export const regionLawyerApi = async (value) => {
  return handleApi("get", `${REGION_LAWYER}/${value.id}?page=${value.page}`);
};

export const filterLawyerApi = async (value) => {
  return handleApi("get", FILTER_LAWYERS + value);
};

export const contactLawyer = async (values) => {
  return handleApi("post", `${CONTACT_LAWYER}/${values.id}`, values);
};
