const practice_areas = [
  {
    id: 1,
    name: "Administrative Law",
    link: "Administrative Law",
  },
  {
    id: 2,
    name: "Admiralty and Maritime Law",
    link: "Admiralty and Maritime Law",
  },
  {
    id: 3,
    name: "Advertising",
    link: "Advertising",
  },
  {
    id: 4,
    name: "Agency, Distribution and Licensing",
    link: "Agency, Distribution and Licensing",
  },
  {
    id: 5,
    name: "Antitrust and Competition",
    link: "Antitrust and Competition",
  },
  {
    id: 6,
    name: "Arbitration, Dispute Resolution & Mediation",
    link: "Arbitration, Dispute Resolution & Mediation",
  },
  {
    id: 7,
    name: "Aviation and aerospace",
    link: "Aviation and aerospace",
  },
  {
    id: 8,
    name: "Banking Law",
    link: "Banking Law",
  },
  {
    id: 9,
    name: "Biotechnology",
    link: "Biotechnology",
  },
  {
    id: 10,
    name: "Business Crime",
    link: "Business Crime",
  },
  {
    id: 11,
    name: "Charities and Foundations",
    link: "Charities and Foundations",
  },
  {
    id: 12,
    name: "Civil Rights",
    link: "Civil Rights",
  },
  {
    id: 13,
    name: "Commercial Law",
    link: "Commercial Law",
  },
  {
    id: 14,
    name: "Commercial Property",
    link: "Commercial Property",
  },
  {
    id: 15,
    name: "Communications Law",
    link: "Communications Law",
  },
  {
    id: 16,
    name: "Constitutional Law",
    link: "Constitutional Law",
  },
  {
    id: 17,
    name: "Construction Law",
    link: "Construction Law",
  },
  {
    id: 18,
    name: "Contracts",
    link: "Contracts",
  },
  {
    id: 19,
    name: "Corporate Law",
    link: "Corporate Law",
  },
  {
    id: 20,
    name: "Criminal Law",
    link: "Criminal Law",
  },
  {
    id: 21,
    name: "Cultural Property",
    link: "Cultural Property",
  },
  {
    id: 22,
    name: "Customs and Excise",
    link: "Customs and Excise",
  },
  {
    id: 23,
    name: "Debt and creditor",
    link: "Debt and creditor",
  },
  {
    id: 24,
    name: "Election Campaign & Political",
    link: "Election Campaign & Political",
  },
  {
    id: 25,
    name: "Employment Benefits",
    link: "Employment Benefits",
  },
  {
    id: 26,
    name: "Energy",
    link: "Energy",
  },
  {
    id: 27,
    name: "Entertainment and Sports",
    link: "Entertainment and Sports",
  },
  {
    id: 28,
    name: "Environmental Law",
    link: "Environmental Law",
  },
  {
    id: 29,
    name: "European Union Law",
    link: "European Union Law",
  },
  {
    id: 30,
    name: "Family Law",
    link: "Family Law",
  },
  {
    id: 31,
    name: "Finance",
    link: "Finance",
  },
  {
    id: 32,
    name: "Foreign Investment",
    link: "Foreign Investment",
  },
  {
    id: 33,
    name: "Franchising",
    link: "Franchising",
  },
  {
    id: 34,
    name: "General Practice",
    link: "General Practice",
  },
  {
    id: 35,
    name: "Government",
    link: "Government",
  },
  {
    id: 36,
    name: "Government Contracts",
    link: "Government Contracts",
  },
  {
    id: 37,
    name: "Health and safety",
    link: "Health and safety",
  },
  {
    id: 38,
    name: "Human Rights Law",
    link: "Human Rights Law",
  },
  {
    id: 39,
    name: "Immigration and Nationality",
    link: "Immigration and Nationality",
  },
  {
    id: 40,
    name: "Information Technology (IT) Law",
    link: "Information Technology (IT) Law",
  },
  {
    id: 41,
    name: "Insolvency, Liquidation & Corporate Rescue",
    link: "Insolvency, Liquidation & Corporate Rescue",
  },
  {
    id: 42,
    name: "Insurance and Re-Insurance",
    link: "Insurance and Re-Insurance",
  },
  {
    id: 43,
    name: "Intellectual Property Law",
    link: "Intellectual Property Law",
  },
  {
    id: 44,
    name: "International Law",
    link: "International Law",
  },
  {
    id: 45,
    name: "International Trade",
    link: "International Trade",
  },
  {
    id: 46,
    name: "Internet Law",
    link: "Internet Law",
  },
  {
    id: 47,
    name: "Investment Companies, Funds and Trusts",
    link: "Investment Companies, Funds and Trusts",
  },
  {
    id: 48,
    name: "Labour and Employment",
    link: "Labour and Employment",
  },
  {
    id: 49,
    name: "Legal Malpractice",
    link: "Legal Malpractice",
  },
  {
    id: 50,
    name: "Litigation",
    link: "Litigation",
  },
  {
    id: 51,
    name: "Medicine and Ethics",
    link: "Medicine and Ethics",
  },
  {
    id: 52,
    name: "Mergers and Acquisitions",
    link: "Mergers and Acquisitions",
  },
  {
    id: 53,
    name: "Natural Resources",
    link: "Natural Resources",
  },
  {
    id: 54,
    name: "Pensions",
    link: "Pensions",
  },
  {
    id: 55,
    name: "Personal Injury",
    link: "Personal Injury",
  },
  {
    id: 56,
    name: "Practice Management and Development",
    link: "Practice Management and Development",
  },
  {
    id: 57,
    name: "Products Liability",
    link: "Products Liability",
  },
  {
    id: 58,
    name: "Professional Liability",
    link: "Professional Liability",
  },
  {
    id: 59,
    name: "Project Finance",
    link: "Project Finance",
  },
  {
    id: 60,
    name: "Real Estate",
    link: "Real Estate",
  },
  {
    id: 61,
    name: "Securities",
    link: "Securities",
  },
  {
    id: 62,
    name: "Taxation",
    link: "Taxation",
  },
  {
    id: 63,
    name: "Technology & Science",
    link: "Technology & Science",
  },
  {
    id: 64,
    name: "Transportation",
    link: "Transportation",
  },
  {
    id: 65,
    name: "Trusts and Estates",
    link: "Trusts and Estates",
  },
  {
    id: 66,
    name: "Zoning, Planning and Land Use",
    link: "Zoning, Planning and Land Use",
  },
  {
    id: 67,
    name: "Chieftaincy",
    link: "Chieftaincy",
  },
  {
    id: 68,
    name: "National Security Law",
    link: "National Security Law",
  },
  {
    id: 69,
    name: "Cyber Law",
    link: "Cyber Law",
  },
  {
    id: 70,
    name: "Maritime Law",
    link: "Maritime Law",
  },
];

export default practice_areas;
