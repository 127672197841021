import { Suspense, useEffect } from "react";
import FallbackLoading from "./common/loader/FallbackLoading";
import { Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes/public.routes";
import TopNavbar from "./common/shared/TopNavbar";
import { useLocation } from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";
import axios from "axios";

function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get("asd");

  const handleProfile = () => {
    const url = "https://gba2.azurewebsites.net/api/v1/auth/profile";
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    axios
      .get(url, { headers })
      .then((response) => {
        const { user, token } = response.data;
        const profile = { user, token };
        localStorage.setItem("profile", JSON.stringify(profile));
      })
      .catch((err) => console.log());
    //   .finally(() => window.location.reload());
  };

  useEffect(() => {
    handleProfile();
  }, [token]);

  return (
    <div className="bg-[#EFEFF1]">
      {/* Conditionally render TopNavbar if not on the login page */}
      {location.pathname !== "/login" && <TopNavbar />}

      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
