const regions = [
  {
    id: 1,
    name: "Greater Accra",
    link: "Greater Accra",
    abbr: "GAR",
  },
  {
    id: 2,
    name: "Ashanti",
    link: "Ashanti",
    abbr: "ASH",
  },
  {
    id: 3,
    name: "Central",
    link: "Central",
    abbr: "CEN",
  },
  {
    id: 4,
    name: "Volta & Oti",
    link: "Volta & Oti",
    abbr: "VOL",
  },
  {
    id: 5,
    name: "Bono, Bono East & Ahafo",
    link: "Bono, Bono East & Ahafo",
    abbr: "BAR",
  },
  {
    id: 6,
    name: "Eastern",
    link: "Eastern",
    abbr: "ESR",
  },
  {
    id: 7,
    name: "Western & Western North",
    link: "Western & Western North",
    abbr: "WSR",
  },
  {
    id: 8,
    name: "Northern, Savannah & North East",
    link: "Northern, Savannah & North East",
    abbr: "NOR",
  },
  {
    id: 9,
    name: "Upper West",
    link: "Upper West",
    abbr: "UWR",
  },
  {
    id: 10,
    name: "Upper East",
    link: "Upper East",
    abbr: "UER",
  },
];

export default regions;
