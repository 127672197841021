import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleChamber } from "../../redux/actions/chamber_actions";
import Loader from "../../common/loader/Loader";
import DetailItem from "../../common/shared/DetailItem";
import { Typography } from "@material-tailwind/react";

function SingleChamber() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { single } = useSelector((state) => state.chamber);

  useEffect(() => {
    dispatch(singleChamber(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  if (single) {
    console.log("<========name========>", single);
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-[#EFEFF1] h-screen">
          <div className="flex items-center shadow-xl px-3 md:px-10 lg:15 xl:px-20 banner">
            <h2 className="px-2 md:px-10 lg:15  text-[2rem] md:text-[3rem] text-white font-bold">
              {single?.name}
            </h2>
          </div>

          <div className="px-2 pt-10 md:px-10 lg:15 xl:px-20 bg-[#EFEFF1]  py-10">
            <div className="grid grid-cols-1 gap-4 my-4">
              <DetailItem
                title="Chamber Address"
                item={single.address !== "" ? single.address : "N/A"}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
              <DetailItem
                title="Phone #"
                item={single.telephone ? single.telephone : "N/A"}
              />

              <DetailItem
                title="Email Address"
                item={single.email ? single.email : "N/A"}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
              <DetailItem
                title="Chamber Head"
                item={
                  single.headOf
                    ? single.headOf.title +
                      " " +
                      single.headOf.first_name +
                      " " +
                      single.headOf.last_name
                    : "N/A"
                }
              />

              <DetailItem
                title="Chamber Deputy"
                item={
                  single.deputyOf
                    ? single.deputyOf.title +
                      " " +
                      single.deputyOf.first_name +
                      " " +
                      single.deputyOf.last_name
                    : "N/A"
                }
              />
            </div>

            <div className="grid grid-cols-1 gap-4 my-4">
              <div className="flex flex-col flex-1 mb-5">
                <Typography
                  color="black"
                  variant="paragraph"
                  className="text-left w-full"
                >
                  <span className="uppercase font-bold text-sm detail-title">
                    Chamber Members
                  </span>
                </Typography>
                {single.chamber_members.length === 0
                  ? "N/A"
                  : single.chamber_members.map((item) => (
                      <Link to={`/lawyers/${item.bar_number}`}>
                        <Typography
                          key={item.id}
                          color="black"
                          variant="paragraph"
                          className="text-left text-xl w-full"
                        >
                          {item.white_list.title +
                            " " +
                            item.white_list.first_name +
                            " " +
                            item.white_list.last_name}
                        </Typography>
                      </Link>
                    ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleChamber;
