import createAsyncAction from "../../utils/asyncAction";

import * as api from "../api/lawyer_api";
import * as types from "../constants/lawyer_contstants";

const getAllLawyersTypes = {
  success: types.ALL_LAWYERS_SUCCESS,
  failure: types.ALL_LAWYERS_FAILURE,
};

const getSingleLawyersTypes = {
  success: types.SINGLE_LAWYER_SUCCESS,
  failure: types.SINGLE_LAWYER_FAILURE,
};

const getYearLawyersTypes = {
  success: types.YEAR_LAWYER_SUCCESS,
  failure: types.YEAR_LAWYER_FAILURE,
};

const getRegionLawyersTypes = {
  success: types.REGION_LAWYER_SUCCESS,
  failure: types.REGION_LAWYER_FAILURE,
};

const getLawyerFilterTypes = {
  success: types.FILTER_LAWYER_SUCCESS,
  failure: types.FILTER_LAWYER_FAILURE,
};

const contactLawyerTypes = {
  success: types.CONTACT_LAWYER_SUCCESS,
  failure: types.CONTACT_LAWYER_FAILURE,
};

export const allLawyers = createAsyncAction(
  api.allLawyersApi,
  getAllLawyersTypes
);

export const singleLawyer = createAsyncAction(
  api.singleLawyerApi,
  getSingleLawyersTypes
);

export const yearLawyer = createAsyncAction(
  api.yearLawyerApi,
  getYearLawyersTypes
);

export const regionLawyer = createAsyncAction(
  api.regionLawyerApi,
  getRegionLawyersTypes
);

export const filterLawyer = createAsyncAction(
  api.filterLawyerApi,
  getLawyerFilterTypes
);

export const contactLawyer = createAsyncAction(
  api.contactLawyer,
  contactLawyerTypes
);
